import Honeybadger from '@honeybadger-io/js';
import { Notyf } from 'notyf';

// eslint-disable-next-line no-undef
const honeybadgerApiKey = process.env.HONEYBADGER_API_KEY;

if (honeybadgerApiKey) {
  // console.log(`Launching ${process.env.RAILS_ENV} Honeybadger Vue`);
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.RAILS_ENV,
  })
}

import { datadogRum } from '@datadog/browser-rum';
// eslint-disable-next-line no-undef
const railsEnvironment = process.env.RAILS_ENV;
// eslint-disable-next-line no-undef
const ddEnvironment = process.env.DD_VERSION;

// DataDog configuration
if (railsEnvironment !== 'development') {
  datadogRum.init({
    // These get exposed on frontend anyway, so there's no point in trying to obfuscate the id
    applicationId: '59032ca4-9046-44da-9b5e-440095d42a1e',
    clientToken: 'pub54032083572441d9352fbf478c90d04d',
    site: 'datadoghq.com',
    service: 'fge-action-plan-tracker',
    env: railsEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // testing for gitlab to see if it deploys version
    version: ddEnvironment,
    sessionSampleRate: 90,
    sessionReplaySampleRate: 10,
    trackUserInteractions: railsEnvironment === 'production',
    trackResources: railsEnvironment === 'production',
    trackLongTasks: railsEnvironment === 'production',
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const { id, dataset } = document.body;
const { flashNotice, flashError } = dataset;

// check for flash
const notyf = new Notyf({
  duration: 7000,
  position: {
    x: 'right',
    y: 'top',
  },
  dismissible: true,
});

if (id === 'home-index') {
  import('../js/views/home/index');
} else if ( id === 'devise-sessions-new') {
  import('../js/views/devise/sessions/new');
} else if ( id === 'devise-passwords-new') {
  import('../js/views/devise/passwords/new');
} else if ( id === 'devise-passwords-edit') {
  import('../js/views/devise/passwords/edit');
}

// Setup header
const profileMenuLink = document.getElementById('profile-menu-link');
const languageMenuLink = document.getElementById('language-menu-link');
const countryMenuLink = document.getElementById('country-menu-link');
const profileDropdown = document.getElementById('profile-dropdown');
const languageDropdown = document.getElementById('language-dropdown');
const countryDropdown = document.getElementById('country-dropdown');

if (profileMenuLink && profileDropdown) {
  profileMenuLink.addEventListener('click', (e) => {
    profileDropdown.classList.toggle('show');
  });
}

if (languageMenuLink && languageDropdown) {
  languageMenuLink.addEventListener('click', (e) => {
    languageDropdown.classList.toggle('show');
  });
}

if (countryMenuLink && countryDropdown) {
  countryMenuLink.addEventListener('click', (e) => {
    countryDropdown.classList.toggle('show');
  });
}

// This will close the dropdown menus if a click occurs outside of them
if (languageMenuLink && profileMenuLink && countryMenuLink) {
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('profile')) {
      profileDropdown.classList.remove('show');
    }
    if (!e.target.classList.contains('language')) {
      languageDropdown.classList.remove('show');
    }
    if (!e.target.classList.contains('country')) {
      countryDropdown.classList.remove('show');
    }
  });
} else if (languageMenuLink) {
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('language')) {
      languageDropdown.classList.remove('show');
    }
  });
} else if (profileMenuLink) {
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('profile')) {
      profileDropdown.classList.remove('show');
    }
  });
} else if (countryMenuLink) {
  document.addEventListener('click', (e) => {
    if (!e.target.classList.contains('country')) {
      countryDropdown.classList.remove('show');
    }
  });
}

const userMenuOverlay = document.getElementById("userMenuOverlay");
const menuOverlay = document.getElementById("menuOverlay");
const closeUserMenuButton = document.getElementById("close-user-menu");
const closeMenuButton = document.getElementById("close-menu");
const userMenuIcon = document.getElementById("mobile-user-menu");
const menuIcon = document.getElementById("mobile-menu");

// Show the full-screen overlay when the userMenuIcon is clicked
if (userMenuIcon) {
  userMenuIcon.addEventListener("click", function () {
    userMenuOverlay.style.display = "block";
  });
}

// Show the full-screen overlay when the menuIcon is clicked
if (menuIcon) {
  menuIcon.addEventListener("click", function () {
    menuOverlay.style.display = "block";
  });
}

function closeMenuHandler() {
  userMenuOverlay.style.display = "none";
  menuOverlay.style.display = "none";
}

// Hide the full-screen overlay when the close button is clicked
if (closeUserMenuButton) {
  closeUserMenuButton.addEventListener("click", closeMenuHandler);
}

if (closeMenuButton) {
  closeMenuButton.addEventListener("click", closeMenuHandler);
}

function submitForm() {
  document.getElementById('language-form').submit();
}

// Add click event listeners to our language options that will submit our language selection form
const englishRadio = document.getElementById('english-radio');
const frenchRadio = document.getElementById('french-radio');

if (englishRadio && frenchRadio) {
  englishRadio.addEventListener("click", function () {
    document.getElementById('language-form').submit();
  });
  frenchRadio.addEventListener("click", function () {
    document.getElementById('language-form').submit();
  });
}
